<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.denpyouBangou"
                :label="$t('nyuuka.label.denpyouBangou')"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.shoriKubun"
                :label="$t('nyuuka.label.shoriKubun')"
                :items="shoriKbnItem"
                item-title="label"
                item-value="key"
                clearable
                return-object
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.nyukamoto"
                :items="nyukamotoItem"
                item-title="code_name"
                item-value="code"
                :label="$t('nyuuka.label.nyukamoto')"
                :clearable="true"
                return-object
              />
            </v-col>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.nyukasaki"
                :items="nyukasakiItem"
                item-title="code_name"
                item-value="code"
                :label="$t('nyuuka.label.nyukasaki')"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.returnType"
                label="返品種別"
                :items="returnTypeItem"
                item-title="name"
                item-value="code"
                clearable
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <DatePicker
                ref="nyukaFrom"
                v-model="searchCondition.nyukaDateFrom"
                :label="$t('nyuuka.label.nyukaDateFrom')"
              ></DatePicker>
            </v-col>
            <v-col cols="3">
              <DatePicker
                v-model="searchCondition.nyukaDateTo"
                ref="nyukaTo"
                :label="$t('nyuuka.label.nyukaDateTo')"
              ></DatePicker>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn text @click="clear">{{ $t('common.action.clear') }}</v-btn>
              <v-btn
                theme="dark"
                class="ml-4 px-8 bg-grey-darken-3"
                elevation="2"
                @click="search"
                :loading="isSearching"
                >{{ $t('common.action.search') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                      {{ $t("nyuuka.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no" @click="rowClick(data)" :style="cursorCheck(null)">
                    <td v-for="header in headers" :key="header">{{ data[header] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click.prevent="downloadCsv"
                :disabled="dataList.length == 0"
                >{{ $t('common.action.outputCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: {AppWrapperView, DatePicker, ZeroData},
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      tempArray = tempArray.map((x, index) => {
        x.nyukaDate = utils.putSplitWordToCalendar(x.nyukaDate);
        x.startDate = utils.putSplitWordToCalendar(x.startDate);
        x.endDate = utils.putSplitWordToCalendar(x.endDate);
        x.no = index + 1;
        return x;
      })

      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },
    shoriKbnItem() {
      const list = [];
      for (const e of JSON.parse(store.getReceiveKbnList()).filter(e => e.isAvailable)) {
        list.push({label: this.$t("common.label.receiveKbn."+e.key), ...e});
      }
      return list;
    },
    // ヘッダー
    headers() {
      return this.$getTableDef("NyuukaPage");
    },
    returnTypeItem() {
      try {
        return JSON.parse(store.getReturnTypeList());
      } catch (e) {
        return [];
      }
    },
  },
  data: () => ({
    currentPageNo: 1,
    prevRoutePath: "",
    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],
    //ソートの状態
    sortState: {target: "", ascent: true},
    nyukamotoItem: [],
    nyukasakiItem: [],

    dataList: [],
    searchCondition: {
      denpyouBangou: "",    //伝票番号
      shoriKubun: "",   //入荷区分
      nyukamoto: null,  //入荷元
      nyukasaki: null, //入荷先
      nyukaDateFrom: "",  //入荷日from
      nyukaDateTo: "", //入荷日to
      returnType: "", //返品種別
    },

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
  }),
  methods: {

    //カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },

    //店舗検索リスト取得
    // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
    getValidShopSearchList(){
      //入荷元店舗リスト取得
      api.post("/store/search", {selectSiteFlag: 0})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.nyukamotoItem.push({name: element.name, code: element.code, code_name: element.code+'_'+element.name})  
        })
      })
      .catch((error) => {
        //do nothing
      })
      //取引先情報取得
      api.post("/business/search", {})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.businessList.forEach(element => {
          this.nyukamotoItem.push({name: element.businessPartnerNameAbbr, code: element.businessPartnerCode, code_name: element.businessPartnerCode+'_'+element.businessPartnerNameAbbr})  
        })
      })
      .catch((error) => {
        //do nothing
      })
      //入荷先店舗リスト取得
      api.post("/store/search", {selectSiteFlag: 1})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.nyukasakiItem.push({name: element.name, code: element.code, code_name: element.code+'_'+element.name})
        })
        if (this.prevRoutePath == Paths.NYUUKA_DETAIL) {
          this.searchCondition.nyukasaki = this.nyukasakiItem.find(e => e.code == this.searchCondition.nyukasaki.code);
          return;
        }
        if (this.isNavigatedFromDashboardPanel()) {
          this.searchCondition.nyukasaki = this.nyukasakiItem.find(e => e.code == store.getDashboardSiteCode());
          return;
        }
        this.searchCondition.nyukasaki = this.nyukasakiItem[0];
      })
      .catch((error) => {
        //do nothing
      })
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    // 入荷明細画面へ遷移
    rowClick(item, row) {
      // 伝票番号
      store.setNyukaDenpyoNumber(item.denpyoNumber);
      // 入荷明細画面
      router.push({name:Paths.NYUUKA_DETAIL})
    },

    clear() {
      this.$refs.nyukaTo.clear()
      this.$refs.nyukaFrom.clear()
      this.searchCondition = {
        denpyouBangou: "",    //伝票番号
        shoriKubun: "",//入荷区分
        nyukamoto: null,  //入荷元店舗 name:codeを中に持っている
        nyukasaki: this.nyukasakiItem[0], //入荷先店舗 ↑と同様
        productCode: "", //品番
        makerProductCode: "", //メーカー品番
        janCode: "",          //JANコード
        // nyukaDateFrom: "",  //入荷日from
        // nyukaDateTo: "", //入荷日to
        returnType: "", //返品種別
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    //${api.basePath}/shop/${store.ownData.getters.currentShopId()}/nyuuka/scm
    search() {
      this.isSearching = true;
      try {
        store.setNyuukaSearchCondition(JSON.stringify(this.searchCondition));
      } catch (e) {
        // do nothin
      }
      api.post("/nyuka/actual/search", {
          denpyoNumber: this.searchCondition.denpyouBangou,
          syoriKubun: this.searchCondition.shoriKubun?.key ?? "",
          nyukamotoCode: this.searchCondition.nyukamoto?.code ?? "",
          nyukasakiCode: this.searchCondition.nyukasaki?.code ?? "",
          nyukaDateFrom: this.searchCondition.nyukaDateFrom?.replaceAll("-","") ?? "",
          nyukaDateTo: this.searchCondition.nyukaDateTo?.replaceAll("-","") ?? "",
          returnType: this.searchCondition.returnType?.code ?? "",
        }
      )
      .then((response)=>{
        this.dataList = response.data.results?.nyukaList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;

      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },
    downloadCsv() {
      utils.simpleDownloadCsv(this.headers, this.sortedTable, "nyuuka.label.table.", 'nyuuka.fileNamePrefix.csv', this.$t);
    },
    initFromDetail() {
      this.searchCondition = JSON.parse(store.getNyuukaSearchCondition());
      if (this.searchCondition.nyukaDateFrom) this.$refs.nyukaFrom.setDate(new Date(this.searchCondition.nyukaDateFrom));
      if (this.searchCondition.nyukaDateTo) this.$refs.nyukaTo.setDate(new Date(this.searchCondition.nyukaDateTo));
      this.search();
    },
    initFromDashboard() {
      const searchDate = new Date(store.getDashboardSearchDate());
      this.$refs.nyukaFrom.setDate(searchDate);
      this.$refs.nyukaTo.setDate(searchDate);
      this.searchCondition.shoriKubun = this.shoriKbnItem.filter(e => e.key == store.getDashboardNyuukaKbn())[0];
      this.searchCondition.nyukasaki = {code: store.getDashboardSiteCode()};
      this.search();
    },
    isNavigatedFromDashboardPanel() {
      // NOTE
      // "遷移元がダッシュボード"という条件だけだと、メニューのリンクを押下しても遷移イベントが発生する。
      // パネル押下時にセッションに店舗コードを設定するので、それの有無でパネルからの遷移を判断する。
      return this.prevRoutePath == Paths.DASHBOARD && store.getDashboardSiteCode();
    },
  },
  created() {
    this.getValidShopSearchList()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoutePath = from?.path;
    })
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);

    if (this.prevRoutePath == Paths.NYUUKA_DETAIL) {
      this.initFromDetail();
      return;
    }
    if (this.isNavigatedFromDashboardPanel()) {
      this.initFromDashboard();
      return;
    }
  }
};
</script>

<style>

</style>
