<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto" v-model="shopCode" :items="shopItem"
                @update:modelValue="reload()" item-title="code_name" item-value="code" :label="$t('dashboard.label.shopCode')"
                :rules="requiredValidation" />
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="4" v-if="showReceiveData">
              <template v-if="receiveDataMap['RECV_PLANNED_QTY']">
                <p class="top_title">{{ $t('dashboard.label.recvPlanQty') }}</p>
                <v-card class="bg-blue mb-1">
                  <v-card-text class="white--text">
                    <div class="contents">
                      <div class="d-flex justify-space-between">
                        <p>{{ receiveDataMap["RECV_PLANNED_QTY"].label }}
                          <span>({{ receiveDataMap["RECV_PLANNED_QTY"].date }})</span></p>
                        <p>{{ receiveDataMap["RECV_PLANNED_QTY"].value }}</p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
              <template v-if="showReceiveResultData">
                <p class="top_title">{{ $t('dashboard.label.recvResultQty') }}</p>
                <template v-for="recv in RecvList">
                  <v-card :to="Paths.NYUUKA" @click="this.navigate2Nyuuka(recv.id, receiveDataMap[recv.code].date)" class="bg-blue mb-1"
                    v-if="receiveDataMap[recv.code]" :key="recv.code">
                    <v-card-text class="white--text">
                      <div class="contents">
                        <div class="d-flex justify-space-between">
                          <p>{{ receiveDataMap[recv.code].label }}
                            <span>({{ receiveDataMap[recv.code].date }})</span></p>
                          <p>{{ receiveDataMap[recv.code].value }}</p>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </template>
            </v-col>
            <v-col cols="4" v-if="showShippedData">
              <template v-if="shippedDataMap['SHIP_PLANNED_QTY']">
                <p class="top_title">{{ $t('dashboard.label.shipPlanQty') }}</p>
                <v-card class="bg-green mb-1">
                  <v-card-text class="white--text">
                    <div class="contents">
                      <div class="d-flex justify-space-between">
                        <p>{{ shippedDataMap["SHIP_PLANNED_QTY"].label }}
                          <span>({{ shippedDataMap["SHIP_PLANNED_QTY"].date }})</span></p>
                        <p>{{ shippedDataMap["SHIP_PLANNED_QTY"].value }}</p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
              <template v-if="showShippedResultData">
                <p class="top_title">{{ $t('dashboard.label.shipResultQty') }}</p>
                <template v-for="ship in ShipList">
                  <v-card :to="Paths.SHUKKA" @click="this.navigate2Shukka(ship.id, shippedDataMap[ship.code].date)" hover class="bg-green mb-1"
                    v-if="shippedDataMap[ship.code]" :key="ship.code">
                    <v-card-text class="white--text">
                      <div class="contents">
                        <div class="d-flex justify-space-between">
                          <p>{{ shippedDataMap[ship.code].label }}
                            <span>({{ shippedDataMap[ship.code].date }})</span></p>
                          <p>{{ shippedDataMap[ship.code].value }}</p>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </template>
            </v-col>
            <v-col cols="4" v-if="showZaikoList">
              <p class="top_title">{{ $t('dashboard.label.inventory') }}</p>
              <v-card :to="Paths.ZAIKO_JAN_LIST" @click="this.navigate2Zaiko()" hover class="bg-grey-darken-2">
                <v-card-text class="white--text">
                  <div class="contents">
                    <div v-for="z in zaikoList" :key="z.zoneCode" class="d-flex justify-space-between">
                      <p>{{ z.zoneName }}</p>
                      <p>{{ z.quantity }}</p>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import Paths from "@/router/indexPaths";
import store from "@/store";

export default {
  components: { AppWrapperView, },
  data: () => ({
    // 店舗検索部品
    shopItem: [],
    shopCode: "",
    // ダッシュボード情報
    receiveDataMap: {},
    shippedDataMap: {},
    zaikoList: [],
    // 表示可否情報
    showReceiveData: false,
    showShippedData: false,
    showReceiveResultData: false,
    showShippedResultData: false,
    showZaikoList: false,
    // ローディング
    isLoading: false,
  }),
  computed: {
    Paths() {
      return Paths;
    },
    RecvList() {
      return [
        { id: 0, code: 'RECV_SINGLE' },
        { id: 1, code: 'RECV_CARTON' },
        { id: 2, code: 'RECV_NO_SCHEDULED' },
        { id: 3, code: 'RECV_SCHEDULED' },
        { id: 4, code: 'RECV_SCM' },
        { id: 5, code: 'RECV_INSPECTION_GRP' },
      ];
    },
    ShipList() {
      return [
        { id: 0, code: 'SHIP_PLANNED' },
        { id: 1, code: 'SHIP_WH_RETURN' },
        { id: 2, code: 'SHIP_STORE_TRANSFER' },
        { id: 3, code: 'SHIP_INSPECTION' },
        { id: 4, code: 'SHIP_PAID_ORDER' },
      ];
    },
  },
  methods: {
    navigate2Shukka(id, date) {
      store.setDashboardSiteCode(this.shopCode);
      store.setDashboardSearchDate(date);
      store.setDashboardShukkaKbn(id);
    },
    navigate2Nyuuka(id, date) {
      store.setDashboardSiteCode(this.shopCode);
      store.setDashboardSearchDate(date);
      store.setDashboardNyuukaKbn(id);
    },
    navigate2Zaiko() {
      store.setDashboardSiteCode(this.shopCode);
    },
    setDashboardData(data) {
      this.receiveDataMap = data.receiveDataMap;
      this.shippedDataMap = data.shippedDataMap;
      this.zaikoList = data.zaikoList;
      // 入荷/出荷/在庫 において表示する内容が無かったら、ブロックごと非表示にする
      this.showReceiveData = Object.keys(this.receiveDataMap).length != 0;
      this.showShippedData = Object.keys(this.shippedDataMap).length != 0;
      this.showReceiveResultData = Object.keys(this.receiveDataMap).filter(e => e != "RECV_PLANNED_QTY").length != 0;
      this.showShippedResultData = Object.keys(this.shippedDataMap).filter(e => e != "SHIP_PLANNED_QTY").length != 0;
      this.showZaikoList = this.zaikoList.length != 0;
    },
    reload() {
      this.isLoading = true;
      api.post("/dashboard/fetch", { siteCode: this.shopCode })
        .then((response) => {
          this.setDashboardData(response.data.results);
        })
        .catch(() => {
          //do nothing
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    // 店舗リストの取得
    // 自店舗検索リスト取得
    // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
    async getValidShopSearchList() {
      await api.post("/store/search", { selectSiteFlag: 1 })
        .then((response) => {
          if (response.status == 1) return; //do nothing
          this.shopItem = [];
          response.data.results?.shopList.forEach(element => {
            this.shopItem.push({ name: element.name, code: element.code, code_name: element.code + '_' + element.name })
          })
          // 先頭値を入れる
          if (this.shopItem.length > 0) this.shopCode = this.shopItem[0].code;
          this.reload();
        })
        .catch(() => {
          //do nothing
        })
    },
  },
  created() {
    this.getValidShopSearchList();
    store.removeDashboardSiteCode();
  },
};
</script>

<style lang="scss" scoped>
.top_title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  margin-top: 8px;

  span {
    font-size: 12px;
    font-weight: normal;
    display: block;
  }
}

.contents {
  font-weight: bold;
  font-size: 20px;

  span {
    font-size: 12px;
  }

  .d-flex.justify-space-between {
    border-bottom: 1px solid #fff;
    margin-bottom: 18px;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
