
import { defineComponent } from "vue";
import store from '@/store/index';
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import api from "@/apis/staff";
import utils from "@/utils"

export default defineComponent({
  name: 'App',
  
  computed: {
    hasError: function () {
      return this.errorMessage !== "";
    },
  },
  data: () => ({
    loginId: "" as string,
    password: "" as string,
    errorMessage: "" as string,
  }),
  methods: {
    requiredValidation() {
      return [(value:string): (string|boolean) => !!value || this.$t('login.message.required')];
    },
    async login(event:any) {
      // バリデーション
      const validationResult = await event;
      if(!(validationResult.valid)) return;

      api.post("/auth/login",{
        loginId: this.loginId,
        password: this.password,
      })
      .then((response) => {

        this.errorMessage = utils.getErrorMsg(response, this.$t)
        if(this.errorMessage != "") return;

        store.initializeSession();
        store.setToken(response.data.results.token);
        store.setUserName(response.data.results.user.name);
        store.setReturnTypeList(response.data.results.htSettings["arrivalReturnType"]);
        router.push({ name: Paths.HOME });
      })
      .catch((error) => {
        this.errorMessage = utils.getErrorMsg(error.response, this.$t)
      });
    },
  },
});

